import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import SearchableDropdown from '../login_signup/searchableDropdown.js';
import { AddCapacityDetails, GetAllCapacitiesDetails } from '../../services/adminServices.js';
import { useModal } from '../modalPopup/modelcontext.js';
import { getToken } from '../../services/loginService.js';
import { GetAllCapacityStatues, GetCapacityMappingOffices } from '../../services/dropdownServices.js';

const CapacityMappings = () => {
    const userInfo = getToken();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedCapacityStatus, setSelectedCapacityStatus] = useState(null);
    const [selectedOffice, setSelectedOffice] = useState(userInfo.officeId);
    const [positionTitle, setPositionTitle] = useState('');
    const [missions, setMissions] = useState([]);
    const [capacityStatuses, setCapacityStatuses] = useState([]);
    const [errors, setErrors] = useState({});
    const { showModal } = useModal();
    const [allCapacities, setAllCapacities] = useState([]);
    const [capacityMappingId, setCapacityMappingId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');   
    const [btnSaveText, setBtnSaveText] = useState('');
    const [isEditClicked, setIsEditClicked] = useState(false);
    //Filter variables 
    const [searchSelectedOffice, setSearchSelectedOffice] = useState(null);
    const [searchSelectedCapacityStatus,setSearchSelectedCapacityStatus] = useState(null);
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);

    const handleAddCapacityClick = () => {
        setIsFormVisible(true);
        setIsEditClicked(false);
        setIsSearchFormVisible(false);
        setBtnSaveText('Add Position');
        setSelectedOffice(userInfo.officeId);
        handleSearchCancelClick();
        const requiredStatus = capacityStatuses.find(status => status.capacityStatusName === 'Required');
        if (requiredStatus) {
            setSelectedCapacityStatus(requiredStatus.capacityStatusId);
        }
      };

    const handleCancelClick = () => {
        setIsFormVisible(false);
        setErrors({});
        setSelectedCapacityStatus(null);
        setSelectedOffice(null);
        setPositionTitle('');
    };

    const handleSeacrhCapacityClick = () => {
        setIsSearchFormVisible(true);
        setIsFormVisible(false);
        clearControls();
      };

    const handleSearchCancelClick = () => {
        setIsSearchFormVisible(false);
        setSearchSelectedOffice(null);
        setSearchSelectedCapacityStatus(null);
    };

    const getOffices = async () => {
        try {
            GetCapacityMappingOffices().then((response) => {
                if (response.status === 200) {
                    setMissions(response.data);    
                }       
             });            
        } catch (error) {
            console.error('Error occured while fetching offices list: ', error);
        }
    };

    const getCapacityStatuses = async () => {
        try {
            GetAllCapacityStatues().then((response) => {
                if (response.status === 200) {
                    setCapacityStatuses(response.data);      
                }       
             });            
        } catch (error) {
            console.error('Error occured while fetching capacity statuses list: ', error);
        }
    };

    const fetchCapacityMappingDetails = useCallback(async() => {
        try{
            GetAllCapacitiesDetails(userInfo.isAdmin).then((response) =>{
                if(response.status === 200){
                    setAllCapacities(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching capacity details:', error);
        }
    }, [userInfo.isAdmin]);

    useEffect(() => { 
        getOffices();
        getCapacityStatuses();
        fetchCapacityMappingDetails();        
    }, [fetchCapacityMappingDetails]);
 
    // Function to calculate pagination and filter data
    const filterTeamsByFormFields = (capacity) => {
        return (
            (!searchSelectedOffice || capacity.officeId === searchSelectedOffice) &&
            (!searchSelectedCapacityStatus || capacity.statusId === parseInt(searchSelectedCapacityStatus, 10))
        );
    };
    const filteredCapacities = allCapacities.filter((capacity) =>(
        (capacity.positionTitle && capacity.positionTitle.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (capacity.statusName && capacity.statusName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (capacity.officeDescription && capacity.officeDescription.toLowerCase().includes(searchQuery.toLowerCase()))) && filterTeamsByFormFields(capacity)
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredCapacities.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCapacities.length / itemsPerPage);
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const startRecord = indexOfFirstItem + 1;
    const endRecord = Math.min(indexOfLastItem, filteredCapacities.length);
    //End Pagination logic here 

    const handleInputChange = (e, setState, fieldName) => {
        setState(e.target.value);
        if (fieldName === 'positionTitle') {
            const isDuplicate = allCapacities.some(item =>
              item.officeId === selectedOffice && item.positionTitle === e.target.value
            );        
            if (isDuplicate) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'The Position name should be unique per Duty Station',
              }));
            }
            else{
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: '',
                  }));
            }
        }else{
            setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '',}));
        }        
      };

    const validate = () => {
        const newErrors = {};
        if (selectedOffice===0) newErrors.selectedOffice = 'Office is required.';
        if (!selectedCapacityStatus) newErrors.selectedCapacityStatus = 'Status is required.';
        if (!positionTitle) newErrors.positionTitle = 'Position Title is required.';
        if(positionTitle && capacityMappingId===0){
            const isDuplicate = allCapacities.some(item =>
                item.officeId === selectedOffice && item.positionTitle === positionTitle
            ); 
            if (isDuplicate) {newErrors.positionTitle = 'The Position name should be unique per Duty Station'; }
        }
        if(positionTitle && capacityMappingId!==0){
            const isDuplicate = allCapacities.some(item =>
                item.officeId === selectedOffice && item.positionTitle === positionTitle && item.capacityMappingId !== capacityMappingId
            ); 
            if (isDuplicate) {newErrors.positionTitle = 'The Position name should be unique per Duty Station'; }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const clearControls=()=>{
        setSelectedCapacityStatus('');
        setSelectedOffice('');
        setPositionTitle('');
        setErrors({});
    }

    const saveCapacityData = async () =>{
        try {
            if (!validate()) {
              return;
            }
              var payload = {capacityMappingId : capacityMappingId,officeId: selectedOffice, positionTitle:positionTitle, statusId:parseInt(selectedCapacityStatus), officeDescription:null,statusName:null};
              AddCapacityDetails(payload).then((res) => {
               if (res.data.success) {
                showModal('success',res.data.message);
                clearControls();
                fetchCapacityMappingDetails();
              } else {
                showModal('failure',res.data.message);
              }       
            });
          } 
          catch (error) {
            showModal('failure','Adding New Capacity failed, Please try again later.');
          }
    };

    const handleUpdateClick = (capacity) => {
        setIsFormVisible(true);
        setIsEditClicked(true);
        setBtnSaveText('Update Position');
        setCapacityMappingId(capacity.capacityMappingId);
        setSelectedOffice(capacity.officeId);
        setSelectedCapacityStatus(capacity.statusId);
        setPositionTitle(capacity.positionTitle);
      };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>Capacity Mappings</h4>
                                        </div>
                                        <div className="col-md-6 text-md-right" style={{textAlign:'right'}}>
                                            <button className='btn btn-primary me-2 mr-2' onClick={handleSeacrhCapacityClick}>Filter Positions</button>
                                            <button className="btn btn-primary" onClick={handleAddCapacityClick}>Add Position</button>
                                        </div>
                                    </div>
                                </div>
                                {isFormVisible && (
                                <div className="card mb-3">
                                    <div className="card-header">
                                    <h6>Capacity Information</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                        <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="officeId" className="form-label">Duty Station <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <SearchableDropdown options={missions} selectedValue={selectedOffice} onChange={(value) => handleInputChange({ target: { value } }, setSelectedOffice, 'selectedOffice')} displayKey="officeDescription" valueKey="officeId" className="w-100" disabled={false}/>
                                                {errors.selectedOffice && <p style={{color: 'red'}}>{errors.selectedOffice}</p>}
                                            </div>                                            
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="statusId" className='form-label'>Status <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <select id="statusId" className="form-select" value={selectedCapacityStatus} onChange={(e) => handleInputChange(e, setSelectedCapacityStatus, 'selectedCapacityStatus')}>
                                                        <option value={''}>--Select--</option>
                                                        {capacityStatuses.map((status) => (
                                                            <option key={status.capacityStatusId} value={status.capacityStatusId}>{status.capacityStatusName}</option>
                                                        ))}
                                                </select>
                                                {errors.selectedCapacityStatus && <p style={{color: 'red'}}>{errors.selectedCapacityStatus}</p>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="positionTitle" className='form-label'>Position Title <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="positionTitle" placeholder="Position Title" value={positionTitle} onChange={(e) => handleInputChange(e, setPositionTitle, 'positionTitle')}/>
                                                {errors.positionTitle && <p style={{color: 'red'}}>{errors.positionTitle}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-6" style={{marginTop:'32px'}}>
                                                <button type="submit" id="btnSave" className="btn btn-primary me-2 mb-2" onClick={saveCapacityData}>{btnSaveText}</button> 
                                                {!isEditClicked && (
                                                    <button type="reset" className="btn btn-secondary me-2 mb-2" onClick={clearControls}>Clear Form</button>
                                                ) }                                                
                                                <button type="button" className="btn btn-danger mb-2" onClick={handleCancelClick}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                {isSearchFormVisible && (
                                    <div className='card mb-3'>
                                    <div className="card-header">
                                        <h6>Search Capacity Details</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                                <div className="form-group mb-3 col-md-6">
                                                    <label htmlFor="searchStatus" className="form-label">Status</label>
                                                        <select id="searchStatus" className="form-select" value={searchSelectedCapacityStatus} onChange={(e) => handleInputChange(e, setSearchSelectedCapacityStatus, 'searchSelectedCapacityStatus')}>
                                                            <option value={''}>--Select--</option>
                                                            {capacityStatuses.map((status) => (
                                                            <option key={status.capacityStatusId} value={status.capacityStatusId}>{status.capacityStatusName}</option>
                                                            ))}
                                                        </select>                                                    
                                                </div>
                                                <div className="form-group mb-3 col-md-6">
                                                    <label htmlFor="searchofficeId" className="form-label">Office</label>
                                                    <SearchableDropdown options={missions} selectedValue={searchSelectedOffice} onChange={(value) => handleInputChange({ target: { value } }, setSearchSelectedOffice, 'searchSelectedOffice')} displayKey="officeDescription" valueKey="officeId" className="w-100" disabled={false}/>
                                                </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group mb-3 col-md-6'>
                                                <button type="button" className="btn btn-danger mb-2" onClick={handleSearchCancelClick}>Remove Filters</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}                                
                                <div id="teamdetailsList" className='card mb-3'>
                                    <div className="card-header">
                                        <h6>Capacity Mappings List</h6>
                                    </div>
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Show{' '}
                                                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="form-select d-inline-block w-auto">
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                    </select>{' '}
                                                entries
                                            </div>
                                            <div className="w-25">
                                                <input type="text" className="form-control" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>          
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                            <table className="table table-bordered table-striped">
                                                <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Possition Title</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Duty Station</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Status</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((capacity) => (
                                                    <tr key={capacity.capacityMappingId}>
                                                    <td>{capacity.positionTitle}</td>
                                                    <td>{capacity.officeDescription}</td>
                                                    <td>{capacity.statusName}</td>
                                                    <td>
                                                        <button className="btn btn-default btn-sm" onClick={() => handleUpdateClick(capacity)}>
                                                        <span className='bi bi-pencil'/>
                                                        </button>
                                                    </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                Showing {startRecord} to {endRecord} of {allCapacities.length} records
                                            </span>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage - 1)}><span className='pi pi-perviousbi bi-skip-backward-fill'></span></button>
                                                    </li>
                                                    {Array.from({ length: totalPages }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage + 1)}><span className='bi bi-skip-forward-fill'/></button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CapacityMappings;
