import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom'; 
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Login from './components/login_signup/Login';
import SignUp from './components/login_signup/Signup';
import Dashboard from './components/dashboard/dashboard';
import { Helmet } from 'react-helmet';
import { config } from './constants/constant';
import { AuthProvider } from './services/authContext';
import ProtectedRoute from './services/protectedRoute';
import TeamDetails from './components/administrator/teamdetails';
import UserDetails from './components/administrator/userdetails';
import CapacityMappings from './components/administrator/capacitymappings';
import ResponseEvent from './components/administrator/responseevent';
import NewDeploymentRequest from './components/deploymentRequest/newDeploymentRequest'; 
import DeploymentRequestsList from './components/deploymentRequest/deploymentRequestsList';
import msalInstance from './services/ssoAuthConfig';
import ViewDeploymentRequest from './components/deploymentRequest/viewDeploymentRequest';
import ForgotPasswordPage from './components/login_signup/forgotPassword';
import ResetPasswordPage from './components/login_signup/resetpassword';
import ChangePasswordPage from './components/profile/changepassword';
import MyProfile from './components/profile/myprofile';
import EmailTemplate from './components/administrator/emailtemplate';
import WBSDetails from './components/administrator/wbsdetails'

function App() {
  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const response = await msalInstance.handleRedirectPromise();
        if (response) {
          console.log('Login successful:', response);
        } else {
          console.log('No redirect response found.');
        }
      } catch (error) {
        console.error('Error handling redirect:', error);
      }
    };

    handleRedirect();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{config.applicationTitle}</title>
      </Helmet>
      <Router>      
      <AuthProvider>
        <Routes>         
        <Route path="/login" element={<Login />} />              
        <Route path="/signup" element={<SignUp />} /> 
        <Route path='/forgotPassword' element={<ForgotPasswordPage/>} />
        <Route path="/resetpassword" element={<ResetPasswordPage/>} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/teamdetails" element={<TeamDetails />} />
            <Route path="/userdetails" element={<UserDetails />} />
            <Route path="/capacitymappings" element={<CapacityMappings />} />
            <Route path='/responseevent' element={<ResponseEvent/>}/>
            <Route path='/newDeploymentRequest' element={<NewDeploymentRequest/>}/>
            <Route path='/newDeploymentRequest/edit/:id' element={<NewDeploymentRequest/>}/>
            <Route path='/deploymentRequestsList' element={<DeploymentRequestsList/>}/>
            <Route path='/viewDeploymentRequest/:id' element={<ViewDeploymentRequest/>}/>
            <Route path='/changepassword' element={<ChangePasswordPage/>}/>
            <Route path='/myprofile' element={<MyProfile/>} />
            <Route path='/emailtemplate' element={<EmailTemplate/>} />
            <Route path='/wbsdetails' element={<WBSDetails/>} />
          </Route>
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
