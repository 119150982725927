import { config } from "../constants/constant";
import axiosInstance from "../constants/globalaxiosinterceptor";
export const adminAPI = config.apiUrl + "/api/Admin";

let adminServiceConfig = {
    headers: {
        'Content-Type': 'application/json',
    }
}
export async function GetAllTeamDetails(){
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_ADDTEAM;
    return await axiosInstance.get(adminAPI+'/teamdetails',{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function AddNewTeam(data) {
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_ADDTEAM;
    return await axiosInstance.post(adminAPI + '/saveteam', data,{headers:adminServiceConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
  }

export async function GetAllUserDetails(){
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_APPROVEUSERSREQUESTS;
    return await axiosInstance.get(adminAPI+'/userdetails',{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function UpdateUserDetails(data) {
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_APPROVEUSERSREQUESTS;
    return await axiosInstance.post(adminAPI + '/updateusers', data,{headers:adminServiceConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function AddCapacityDetails(data) {
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_CAPACITYGAPSDE;
    return await axiosInstance.post(adminAPI + '/capacitymappings', data,{headers:adminServiceConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllCapacitiesDetails(isAdmin){
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_CAPACITYGAPSDE;
    adminServiceConfig.headers.isAdmin = isAdmin;
    return await axiosInstance.get(adminAPI+'/capacitydetails',{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function GetAllResponseEvents(){
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_ADDRESPONSE;
    return await axiosInstance.get(adminAPI+'/responseevents',{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function AddResponseEvents(data){
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_ADDRESPONSE;
    return await axiosInstance.post(adminAPI+'/addresponseevents', data ,{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function UpdateResponseEventsStatus(data){
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_ADDRESPONSE;
    return await axiosInstance.post(adminAPI+'/updateresponsestatus', data ,{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function GetUserDetailsByUserId(){
    return await axiosInstance.get(adminAPI+'/userdetailsbyuserid',{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function UpdateMyProfileData(data){
    return await axiosInstance.post(adminAPI+'/updatemyprofile', data ,{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function GetAllWBSDetails(){
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_Create_WBS;
    return await axiosInstance.get(adminAPI+'/wbsdetails',{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}

export async function AddWbsDetails(data) {
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_Create_WBS;
    return await axiosInstance.post(adminAPI + '/addwbsdetails', data,{headers:adminServiceConfig.headers}).catch((response) => {
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    });
}

export async function GetAllWBSForDropdown() {
    adminServiceConfig.headers.roleName = process.env.REACT_APP_ROLENAME_PROVIDEWBS;
    return await axiosInstance.get(adminAPI+'/getAllWBSForDropdown',{headers:adminServiceConfig.headers} ).catch((response) =>{
        if (response.code === 200) {
            return (response.data);
        }
        else {
            return response.message;
        }
    }); 
}