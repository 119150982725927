import React,{ useState } from 'react';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './sidebar.css';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse'
import { Collapse } from 'react-bootstrap';
import { useAuth } from '../../services/authContext';
import { userRoles, getToken } from '../../services/loginService';

const Sidebar = () => {
    const [openRequest, setOpenRequest] = useState(true);
    const [openAdministrator, setOpenAdministrator] = useState(true);
    const [openProfile, setOpenProfile] = useState(true);
    const toggleRequests = () => setOpenRequest(!openRequest);
    const toggleAdministrator = () => setOpenAdministrator(!openAdministrator);
    const toggleProfile = () => setOpenProfile(!openProfile);
    const { logout } = useAuth();
    const roles = userRoles();
    const userInfo = getToken();
    const hasAccess = (requiredRoles) => {
        return roles.some(role => requiredRoles.includes(role.roleName) || requiredRoles.includes(role.roleGroup));
    };
  return (
    <div className='sidebar bg-light-iom'>
        <div className='d-flex flex-column justify-content-between'>
            <div className='mt-2'>
                <ul className="nav nav-pills flex-column mt-2 mt-sm-0" id='parentM'>
                    <li className="nav-item my-1 py-2 py-sm-0">
                        <Link to="/dashboard" className="nav-link text-white text-center text-sm-start">
                            <i className="bi bi-person"></i>
                            <span className='ms-2 d-none d-sm-inline'>Dashboard</span>
                        </Link>
                    </li>
                    <nav className="nav flex-column">
                        <li className="nav-item my-1 py-2 py-sm-0">
                            <div onClick={toggleProfile} className="nav-link text-white text-center text-sm-start" aria-current="page" style={{ cursor: 'pointer' }}>
                                <i className="bi bi-person-square"></i>
                                <span className="ms-2 d-none d-sm-inline">Profile</span>
                                <i className={`bi bi-caret-${openProfile ? 'down' : 'right'}-fill ms-0 ms-sm-3 mt-1 float-end`}></i>
                            </div>
                            <Collapse in={openProfile}>
                                <ul className="nav ms-2 flex-column" id="submenu">
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/myprofile">
                                        <span className="d-none d-sm-inline">My Profile</span>
                                        </Link>
                                    </li>
                                    {(userInfo.accountType === "Personal Account") && (
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/changepassword">
                                        <span className="d-none d-sm-inline">Change Password</span>
                                        </Link>
                                    </li>
                                    )}                                    
                                </ul>
                            </Collapse>
                        </li>
                    </nav>                  
                    <li className="nav-item my-1 py-2 py-sm-0">
                        <Link to="#" className="nav-link text-white text-center text-sm-start" aria-current="page">
                        <i className="bi bi-file-earmark-post"></i>
                            <span className='ms-2 d-none d-sm-inline'>Reports</span>
                        </Link>
                    </li>
                    {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLEGROUP_REQUESTERS, process.env.REACT_APP_ROLEGROUP_ACCOUNTADMIN, process.env.REACT_APP_ROLEGROUP_ESFMSUPPORTER, process.env.REACT_APP_ROLEGROUP_SBPSUPPORTER])) && (
                    <nav className="nav flex-column">
                        <li className="nav-item my-1 py-2 py-sm-0">
                            <div onClick={toggleRequests} className="nav-link text-white text-center text-sm-start" aria-current="page" style={{ cursor: 'pointer' }}>
                                <i className="bi bi-list-ul"></i>
                                <span className="ms-2 d-none d-sm-inline">Requests</span>
                                <i className={`bi bi-caret-${openRequest ? 'down' : 'right'}-fill ms-0 ms-sm-3 mt-1 float-end`}></i>
                            </div>
                            <Collapse in={openRequest}>
                                <ul className="nav ms-2 flex-column" id="submenu">
                                    {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLENAME_CAPACITYGAPSDE])) && (
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/capacitymappings">
                                        <span className="d-none d-sm-inline">Capacity Mapping</span>
                                        </Link>
                                    </li>
                                    )}
                                    {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLENAME_DRAFTREQUEST, process.env.REACT_APP_ROLENAME_SUBMITREQUEST])) && (
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/newDeploymentRequest">
                                        <span className="d-none d-sm-inline">New Request</span>
                                        </Link>
                                    </li>
                                    )}                                    
                                    {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLENAME_DRAFTREQUEST, process.env.REACT_APP_ROLENAME_SUBMITREQUEST, process.env.REACT_APP_ROLENAME_REVIEWREQUESTESFM, process.env.REACT_APP_ROLENAME_APPROVEREQUESTESFM, process.env.REACT_APP_ROLENAME_REVIEWREQUESTSBP, process.env.REACT_APP_ROLENAME_APPROVEREQUESTSBP])) && (
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/deploymentRequestsList">
                                        <span className="d-none d-sm-inline">List of Requests</span>
                                        </Link>
                                    </li>
                                    )}
                                </ul>
                            </Collapse>
                        </li>
                    </nav>
                    )}
                    {hasAccess([process.env.REACT_APP_ROLEGROUP_ACCOUNTADMIN]) && (
                    <nav className="nav flex-column">
                        <li className="nav-item my-1 py-2 py-sm-0">
                            <div onClick={toggleAdministrator} className="nav-link text-white text-center text-sm-start" aria-current="page" style={{ cursor: 'pointer' }}>
                                <i className="bi bi-person-circle"></i>
                                <span className="ms-2 d-none d-sm-inline">Administrator</span>
                                <i className={`bi bi-caret-${openAdministrator ? 'down' : 'right'}-fill ms-0 ms-sm-3 mt-1 float-end`}></i>
                            </div>
                            <Collapse in={openAdministrator}>
                                <ul className="nav ms-2 flex-column" id="submenu">
                                    {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLENAME_ADDTEAM])) && (
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/teamdetails">
                                        <span className="d-none d-sm-inline">Team Management</span>
                                        </Link>
                                    </li>
                                    )}                                    
                                    {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLENAME_APPROVEUSERSREQUESTS])) && (
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/userdetails">
                                        <span className="d-none d-sm-inline">User Management</span>
                                        </Link>
                                    </li> 
                                    )} 
                                    {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLENAME_ADDRESPONSE])) && (
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/responseevent">
                                        <span className="d-none d-sm-inline">Response Events Management</span>
                                        </Link>
                                    </li>
                                    )}
                                    {/* {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLENAME_UPDATEEMAILTEMPLATE])) && (
                                    <li className="nav-item w-100">
                                        <Link className="nav-link text-white" to="/emailtemplate">
                                        <span className="d-none d-sm-inline">Email Template Management</span>
                                        </Link>
                                    </li>  
                                    )} */}
                                    {(userInfo.isAdmin || hasAccess([process.env.REACT_APP_ROLENAME_PROVIDEWBS])) && (
                                    <li className="nav-item w-100">
                                       <Link className="nav-link text-white" to="/wbsdetails">
                                       <span className="d-none d-sm-inline">Create WBS</span>
                                       </Link>
                                    </li>   
                                    )}
                                </ul>
                            </Collapse>
                        </li>
                    </nav>
                    )}
                    <li className="nav-item my-1 py-2 py-sm-0">
                        <Link to="/" onClick={logout} className="nav-link text-white text-center text-sm-start" aria-current="page">
                            <i className="bi bi-box-arrow-left"></i>
                            <span className='ms-2 d-none d-sm-inline'>Logout</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div> 
    );
}
export default Sidebar;