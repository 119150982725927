import axios from 'axios';
import { getToken, refreshToken } from '../services/loginService';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

const axiosInstance = axios.create({
});

// Add a request interceptor to include Authorization header with JWT token
axiosInstance.interceptors.request.use(config => {
  const userInfo = getToken();
  config.headers['Authorization'] = `Bearer ${userInfo?.token}`;
  config.headers['UserId'] = userInfo?.userId;
if(userInfo?.token !== ''){
  var tempVal = jwtDecode(userInfo?.token);
  const isExpired = dayjs.unix(tempVal.exp).diff(dayjs()) < 1;
  if (!isExpired){
    return config; 
  } 
  else{
    var payload = {token : userInfo?.token, refreshToken : userInfo?.refreshToken}
    refreshToken(payload).then((result) => {
      if (result?.data?.success){
        localStorage.setItem('isAuthenticated', 'true'); 
        localStorage.setItem("userInfo",JSON.stringify(result.data));
        config.headers['Authorization'] = `Bearer ${result?.data?.token}`;
        config.headers['UserId'] = result?.data?.userId;
        return config;
      }
    });
  }
}
return config; 
});

export default axiosInstance;
