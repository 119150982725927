import React, { useState, useEffect } from 'react';
import Header from '../../assets/header/Header.js';
import Sidebar from '../../assets/sidebar/sidebar.js';
import SearchableDropdown from '../login_signup/searchableDropdown.js';
import { AddNewTeam, GetAllTeamDetails } from '../../services/adminServices.js';
import { config } from '../../constants/constant.js';
import { useModal } from '../modalPopup/modelcontext.js';
import { GetTeamDetailsOffices, GetAllSupportTypes, GetAllTeamTypes, GetAllWorkFlows } from '../../services/dropdownServices.js';

const TeamDetails = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedTeamType, setSelectedTeamType] = useState(null);
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [teamName, setTeamName] = useState('');
    const [dlEmail,setDlEmail] = useState('');
    const [selectedWorkFlow, setSelectedWorkFlow] = useState(null);
    const [isSupporter, setIsSupporter] = useState(false);
    const [selectedSupportType,setSelectedSupportType] = useState(null);
    const [missions, setMissions] = useState([]);
    const [teamTypes, setTeamTypes] = useState([]);
    const [workFlows, setWorkFlows] = useState([]);
    const [supportTypes,setSupportTypes] = useState([]);
    const [errors, setErrors] = useState({});
    const { showModal } = useModal();
    const [teams, setTeams] = useState([]);
    const [setTeamId, setSelectedTeamId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');   
    const [btnSaveText, setBtnSaveText] = useState('');
    const [isEditClicked, setIsEditClicked] = useState(false);
    //Filter variables 
    const [searchSelectedTeamType, setSearchSelectedTeamType] = useState(null);
    const [searchSelectedOffice, setSearchSelectedOffice] = useState(null);
    const [searchIsSupporter, setSearchIsSupporter] = useState(false);
    const [searchSelectedSupportType,setSearchSelectedSupportType] = useState(null);
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);

    const handleAddTeamClick = () => {
        setIsFormVisible(true);
        setIsEditClicked(false);
        setIsSearchFormVisible(false);
        setBtnSaveText('Add Team');
        handleSearchCancelClick();
      };

    const handleCancelClick = () => {
        setIsFormVisible(false);
        setErrors({});
        setSelectedTeamType(null);
        setSelectedOffice(null);
        setSelectedWorkFlow(null);
        setIsSupporter(false);
        setSelectedSupportType(null);
    };

    const handleSeacrhTeamClick = () => {
        setIsSearchFormVisible(true);
        setIsFormVisible(false);
        clearControls();
      };

    const handleSearchCancelClick = () => {
        setIsSearchFormVisible(false);
        setSearchSelectedTeamType(null);
        setSearchSelectedOffice(null);
        setSearchIsSupporter(false);
        setSearchSelectedSupportType(null);
    };

    const fetchOffices = async () => {
        try {
            GetTeamDetailsOffices().then((response) => {
                if (response.status === 200) {
                    setMissions(response.data);       
               }       
             });            
        } catch (error) {
            console.error('Error fetching offices: ', error);
        }
    };

    const fetchTeamTypes = async () => {
        try {
            GetAllTeamTypes().then((response) => {
                if (response.status === 200) {
                    setTeamTypes(response.data);          
               }       
             });            
        } catch (error) {
            console.error('Error fetching team types: ', error);
        }
    };

    const fetchWorkFlows = async () => {
        try {
            GetAllWorkFlows().then((response) => {
                if (response.status === 200) {
                    setWorkFlows(response.data);          
               }       
             });            
        } catch (error) {
            console.error('Error fetching workflows: ', error);
        }
    };

    const fetchSupportTypes = async () => {
        try {
            GetAllSupportTypes().then((response) => {
                if (response.status === 200) {
                    setSupportTypes(response.data);          
               }       
             });            
        } catch (error) {
            console.error('Error fetching team types: ', error);
        }
    };

    const fetchTeamDetails = async() => {
        try{
            GetAllTeamDetails().then((response) =>{
                if(response.status === 200){
                    setTeams(response.data);
                }
            });
        }catch(error){
            console.error('Error while fetching team details:', error);
        }
    };

    useEffect(() => { 
        fetchOffices();
        fetchTeamTypes();
        fetchWorkFlows();
        fetchSupportTypes();
        fetchTeamDetails();
    }, []);
 
    // Function to calculate pagination and filter data
    const filterTeamsByFormFields = (team) => {
        return (
            (!searchSelectedTeamType || team.teamTypeId === parseInt(searchSelectedTeamType, 10)) &&
            (!searchSelectedOffice || team.officeId === searchSelectedOffice) &&
            (!searchIsSupporter || team.isSupporter === searchIsSupporter) &&
            (!searchSelectedSupportType || team.supportId === parseInt(searchSelectedSupportType, 10))
        );
    };
    const filteredTeams = teams.filter((team) =>(
        (team.teamName && team.teamName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (team.teamTypeName && team.teamTypeName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (team.wfName && team.wfName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (team.officeDescription && team.officeDescription.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (team.supportTypeName && team.supportTypeName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (team.dlEmailId && team.dlEmailId.toLowerCase().includes(searchQuery.toLowerCase()))
    ) && filterTeamsByFormFields(team)
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredTeams.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredTeams.length / itemsPerPage);
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const startRecord = indexOfFirstItem + 1;
    const endRecord = Math.min(indexOfLastItem, filteredTeams.length);
    //End Pagination logic here 

    const handleInputChange = (e, setState, fieldName) => {
        setState(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '',}));
      };

    const handleCheckboxChange = (e) => {
        setIsSupporter(e.target.checked);
        if (!e.target.checked) {
            setSelectedSupportType(null);
          }
    };

    const handleSearchCheckboxChange = (e) => {
        setSearchIsSupporter(e.target.checked);
        if (!e.target.checked) {
            setSearchSelectedSupportType(null);
          }
    }
    const validate = () => {
        const newErrors = {};
        if (!selectedTeamType) newErrors.selectedTeamType = 'Team Type is required.';
        if (selectedOffice===0) newErrors.selectedOffice = 'Office is required.';
        if (!teamName) newErrors.teamName = 'Team Name is required.';
        if (!dlEmail) newErrors.dlEmail = 'DL Email is required.';
        else if (!config.emailRegex.test(dlEmail)) newErrors.dlEmail = 'Please enter a valid email address.';
        if (!selectedWorkFlow) newErrors.selectedWorkFlow = 'Work Flow is required.';
        // if (!isSupporter) newErrors.isSupporter = 'Supporter is required.';
        if(isSupporter){
            if(!selectedSupportType) newErrors.selectedSupportType = 'Supporter Type is required.'
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const clearControls=()=>{
        setSelectedTeamType('');
        setSelectedOffice('');
        setTeamName('');
        setDlEmail('');
        setSelectedWorkFlow('');
        setIsSupporter(false);
        setSelectedSupportType('');
        setErrors({});
    }

    const saveTeamData = async () =>{
        try {
            if (!validate()) {
              return;
            }
              var payload = {teamId : setTeamId,teamTypeId:parseInt(selectedTeamType,10), officeId: selectedOffice, teamName:teamName, dlEmailId : dlEmail, isSupporter: isSupporter, workFlowId : parseInt(selectedWorkFlow,10), supportId:selectedSupportType!=null ? parseInt(selectedSupportType,10) : selectedSupportType};
              AddNewTeam(payload).then((res) => {
               if (res.data.success) {
                showModal('success',res.data.message);
                clearControls();
                fetchTeamDetails();
              } else {
                showModal('failure',res.data.message);
              }       
            });
          } 
          catch (error) {
            showModal('failure','Adding New Team failed, Please try again later.');
          }
    };

    const handleUpdateClick = (team) => {
        setIsFormVisible(true);
        setIsEditClicked(true);
        setBtnSaveText('Update Team');
        setSelectedTeamId(team.teamId);
        setSelectedOffice(team.officeId);
        setSelectedTeamType(team.teamTypeId);
        setSelectedWorkFlow(team.workFlowId);
        setIsSupporter(team.isSupporter);
        setSelectedSupportType(team.supportId== null ? '' : team.supportId);
        setTeamName(team.teamName);
        setDlEmail(team.dlEmailId);
      };

    return (
        <>
            <Header />
            <div className="d-flex">
                <Sidebar />
                <div className="main bg-light-iom">
                    <div className='dashboard-class'>
                        <div className='cont-wrapper'>
                            <div className="container mt-5">
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <h4>Teams Details</h4>
                                        </div>
                                        <div className="col-md-6 text-md-right" style={{textAlign:'right'}}>
                                            <button className='btn btn-primary me-2 mr-2' onClick={handleSeacrhTeamClick}>Filter Teams</button>
                                            <button className="btn btn-primary" onClick={handleAddTeamClick}>Add New Team</button>
                                        </div>
                                    </div>
                                </div>
                                {isFormVisible && (
                                <div className="card mb-3">
                                    <div className="card-header">
                                    <h6>Team Information</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="teamType" className="form-label">Team Type <span style={{color:"red", font:"bold"}}>*</span></label>
                                                    <select id="teamType" className="form-select" value={selectedTeamType} onChange={(e) => handleInputChange(e, setSelectedTeamType, 'selectedTeamType')}>
                                                        <option value={''}>--Select--</option>
                                                        {teamTypes.map((type) => (
                                                            <option key={type.teamTypeId} value={type.teamTypeId}>{type.teamTypeName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.selectedTeamType && <p style={{color: 'red'}}>{errors.selectedTeamType}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="officeId" className="form-label">Office <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <SearchableDropdown options={missions} selectedValue={selectedOffice} onChange={(value) => handleInputChange({ target: { value } }, setSelectedOffice, 'selectedOffice')} displayKey="officeDescription" valueKey="officeId" className="w-100" disabled={false}/>
                                                {errors.selectedOffice && <p style={{color: 'red'}}>{errors.selectedOffice}</p>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="teamName" className='form-label'>Team Name <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="text" className="form-control" id="teamName" placeholder="Team Name" value={teamName} onChange={(e) => handleInputChange(e, setTeamName, 'teamName')}/>
                                                {errors.teamName && <p style={{color: 'red'}}>{errors.teamName}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="dlEmail" className='form-label'>DL Email <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <input type="email" className="form-control" id="dlEmail" placeholder="DL Email" value={dlEmail} onChange={(e) => handleInputChange(e, setDlEmail, 'dlEmail')}/>
                                                {errors.dlEmail && <p style={{color: 'red'}}>{errors.dlEmail}</p>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6">
                                                <label htmlFor="workFlowId" className='form-label'>Work Flow <span style={{color:"red", font:"bold"}}>*</span></label>
                                                <select id="workFlowId" className="form-select" value={selectedWorkFlow} onChange={(e) => handleInputChange(e, setSelectedWorkFlow, 'selectedWorkFlow')}>
                                                        <option value={''}>--Select--</option>
                                                        {workFlows.map((workflow) => (
                                                            <option key={workflow.workFlowId} value={workflow.workFlowId}>{workflow.workFlowName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.selectedWorkFlow && <p style={{color: 'red'}}>{errors.selectedWorkFlow}</p>}
                                            </div>
                                            <div className="form-group mb-3 col-md-6" style={{marginTop:'40px'}}>
                                                <input id='isSupporter' type='checkbox' className='me-2 mb-2' checked={isSupporter} onChange={handleCheckboxChange}></input>
                                                <label htmlFor="isSupporter" className='form-label'>Supporter </label>
                                                {errors.isSupporter && <p style={{color: 'red'}}>{errors.isSupporter}</p>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            {isSupporter && (
                                            <div className='form-group mb-3 col-md-6'>
                                            <label htmlFor="supportType" className='form-label'>Support Type <span style={{color:"red", font:"bold"}}>*</span></label>   
                                            <select id="supportType" className="form-select" value={selectedSupportType} onChange={(e) => handleInputChange(e, setSelectedSupportType, 'selectedSupportType')}>
                                                        <option defaultValue={''}>--Select--</option>
                                                        {supportTypes.map((support) => (
                                                            <option key={support.supportTypeId} value={support.supportTypeId}>{support.supportTypeName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.selectedSupportType && <p style={{color: 'red'}}>{errors.selectedSupportType}</p>}
                                            </div>
                                            )}
                                            <div className="form-group mb-3 col-md-6" style={{marginTop:'32px'}}>
                                                <button type="submit" id="btnSave" className="btn btn-primary me-2 mb-2" onClick={saveTeamData}>{btnSaveText}</button> 
                                                {!isEditClicked && (
                                                    <button type="reset" className="btn btn-secondary me-2 mb-2" onClick={clearControls}>Clear Form</button>
                                                )}
                                                <button type="button" className="btn btn-danger mb-2" onClick={handleCancelClick}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                {isSearchFormVisible && (
                                    <div className='card mb-3'>
                                    <div className="card-header">
                                        <h6>Search Team Details</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                                <div className="form-group mb-3 col-md-6">
                                                    <label htmlFor="searchteamType" className="form-label">Team Type</label>
                                                        <select id="searchteamType" className="form-select" value={searchSelectedTeamType} onChange={(e) => handleInputChange(e, setSearchSelectedTeamType, 'searchSelectedTeamType')}>
                                                            <option value={''}>--Select--</option>
                                                            {teamTypes.map((type) => (
                                                                <option key={type.teamTypeId} value={type.teamTypeId}>{type.teamTypeName}</option>
                                                            ))}
                                                        </select>                                                    
                                                </div>
                                                <div className="form-group mb-3 col-md-6">
                                                    <label htmlFor="searchofficeId" className="form-label">Office</label>
                                                    <SearchableDropdown options={missions} selectedValue={searchSelectedOffice} onChange={(value) => handleInputChange({ target: { value } }, setSearchSelectedOffice, 'searchSelectedOffice')} displayKey="officeDescription" valueKey="officeId" className="w-100" disabled={false}/>
                                                </div>
                                        </div>
                                        <div className='row'>
                                            <div className="form-group mb-3 col-md-6" style={{marginTop:'40px'}}>
                                                <input id='searchIsSupporter' type='checkbox' className='me-2 mb-2' checked={searchIsSupporter} onChange={handleSearchCheckboxChange}></input>
                                                <label htmlFor="searchIsSupporter" className='form-label'>Supporter </label>
                                            </div>
                                            {searchIsSupporter && (
                                            <div className='form-group mb-3 col-md-6'>
                                                    <label htmlFor="searchSupportType" className='form-label'>Support Type</label>   
                                                    <select id="searchSupportType" className="form-select" value={searchSelectedSupportType} onChange={(e) => handleInputChange(e, setSearchSelectedSupportType, 'searchSelectedSupportType')}>
                                                        <option value={''}>--Select--</option>
                                                        {supportTypes.map((support) => (
                                                            <option key={support.supportTypeId} value={support.supportTypeId}>{support.supportTypeName}</option>
                                                        ))}
                                                    </select>                                                    
                                            </div>
                                            )}
                                        </div>
                                        <div className='row'>
                                            <div className='form-group mb-3 col-md-6'>
                                                <button type="button" className="btn btn-danger mb-2" onClick={handleSearchCancelClick}>Remove Filters</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}                                
                                <div id="teamdetailsList" className='card mb-3'>
                                    <div className="card-header">
                                        <h6>Team Details List</h6>
                                    </div>
                                        <div className='card-body'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Show{' '}
                                                    <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="form-select d-inline-block w-auto">
                                                        <option value={5}>5</option>
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                    </select>{' '}
                                                entries
                                            </div>
                                            <div className="w-25">
                                                <input type="text" className="form-control" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>          
                                        <div className="table-responsive" style={{fontSize:"0.9rem"}}>
                                            <table className="table table-bordered table-striped">
                                                <thead className="thead-light" style={{background:"ghostwhite"}}>
                                                    <tr>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Team Name</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Team Type</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Office</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>DL Email</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Supporter</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Supporter Type</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>WorkFlow</th>
                                                        <th style={{background:"#7a9fd9", textAlign: 'center', verticalAlign: 'middle'}}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((team) => (
                                                    <tr key={team.teamId}>
                                                    <td>{team.teamName}</td>
                                                    <td>{team.teamTypeName}</td>
                                                    <td>{team.officeDescription}</td>
                                                    <td>{team.dlEmailId}</td>
                                                    <td>{team.isSupporter ? 'Yes' : 'No'}</td>
                                                    <td>{team.supportTypeName}</td>
                                                    <td>{team.wfName}</td>
                                                    <td>
                                                        <button className="btn btn-default btn-sm" onClick={() => handleUpdateClick(team)}>
                                                        <span className='bi bi-pencil'/>
                                                        </button>
                                                    </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                Showing {startRecord} to {endRecord} of {teams.length} records
                                            </span>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage - 1)}><span className='pi pi-perviousbi bi-skip-backward-fill'></span></button>
                                                    </li>
                                                    {Array.from({ length: totalPages }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(index + 1)}>{index + 1}</button>
                                                    </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button className="page-link" onClick={() => goToPage(currentPage + 1)}><span className='bi bi-skip-forward-fill'/></button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeamDetails;
